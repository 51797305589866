import React from "react";
import Layout from "../components/layout/Layout";
import ButtonPrimary from "../components/misc/ButtonPrimary";
import Seo from "../components/seo";
import { Link } from "gatsby";

const AboutPage = () => (
  <Layout>
    <Seo title="Hakkimizda" />
    <section className="text-gray-600 body-font mt-24">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="text-xs uppercase text-brand tracking-widest font-medium title-font mb-1">
            Istiklal Network
          </h2>
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 dark:text-gray-300">
            Hakkımızda
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base dark:text-gray-300">
            Yaptığımız projeler hakkında bilgi almak ve siz de aklınızdaki
            projeyi bizim ile paylaşmak için bize ulaşabilirsiniz.
          </p>
        </div>
        <div className="flex flex-wrap">
          <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Web Tasarım
            </h2>
            <p className="leading-relaxed text-base mb-4 dark:text-gray-300">
              Son teknoloji ile tasarlanmış tüm cihazlarla uyumlu görüntülenen
              akıllı tasarımlar.
            </p>
          </div>
          <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Web Yazılım
            </h2>
            <p className="leading-relaxed text-base mb-4 dark:text-gray-300">
              Web ve mobil uygulamanız için kullanabileceğiniz güçlü RESTFUL
              API.
            </p>
          </div>
          <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              iOS Uygulama Geliştirme
            </h2>
            <p className="leading-relaxed text-base mb-4 dark:text-gray-300">
              Mobil uygulama marketlerinde de yerinizi almak için iOS uygulama.
            </p>
          </div>
          <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
            <h2 className="text-lg sm:text-xl text-gray-900 font-medium title-font mb-2">
              Android Uygulama Geliştirme
            </h2>
            <p className="leading-relaxed text-base mb-4 dark:text-gray-300">
              Mobil uygulama marketlerinde de yerinizi almak için Android
              uygulama.
            </p>
          </div>
        </div>
        <Link to="/tr/contact">
          <ButtonPrimary addClass="flex mx-auto mt-16">
            Bize Ulaşın
          </ButtonPrimary>
        </Link>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
